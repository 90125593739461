import React from "react"
import BaseLayout from "../layout/BaseLayout"
import { KVList } from "../components/kvlist"

const ShowcasePage = (props) => {
  const { pageContext } = props
  const node = pageContext.node
  const { title, year, customer, project_type_full, used_tech_full, my_tasks_full } = node
  //const {teaser, path, teaserdescription} = node;
  //const components = {BlogList,ProjectsList,DoubleHeader,Cards,Card,SmallButton}
  let ptf = project_type_full.map(p => p.name)
  let utf = used_tech_full.map(u => u.name)
  let mtf = my_tasks_full.map(m => m.name)

  const listValues = [
    { label: "Jahr", value: year },
    { label: "Projekt", value: title },
    { label: "Kunde", value: customer },
    { label: "Projektart", value: ptf },
    { label: "Tech", value: utf },
    { label: "Meine Aufgabe", value: mtf },
  ]

  return (
    <BaseLayout context={pageContext}>

      <div className="content_inner content_centered content_padding_top">
        <h1>{node.title}</h1>
        <div className="showcase_page_info_box">
          <KVList className={"preview_data_list col-md-6"} list={listValues}/>
          <div className="description" dangerouslySetInnerHTML={{ __html: node.description_rendered }}/>

        </div>

        <div className="slides">
          {node.images_urls.map((imgs) => {
            let fixed = imgs?.url?.localFile?.childImageSharp?.fixed?.src
            return <div className="slide">
              <img src={fixed}/>
            </div>
          })}
        </div>


      </div>


    </BaseLayout>
  )
}

export default ShowcasePage
