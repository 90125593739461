import React from "react"

const KVRow = (props) => {
  const { label, value } = props

  let val = typeof value === "string" ? value : value.join(", ");

  return (
    <div className="kv_row">
      <span className={"label"}>{label} :</span>
      <span className="value">{val}</span>
    </div>
  )
}
export const KVList = (props) => {

  const { list, className } = props

  return (
    <div className={`kv_list ${className}`}>
      {list.map((item) => <KVRow key={item.label} withLabel label={item.label} value={item.value}/>)}
    </div>
  )
}
